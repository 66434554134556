import Actions from './Actions';
import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from './Mutations';
import SaleReturnService from '@/core/services/salesreturn/ReturnSaleService';
import InfoSaleReturn from '@/models/salesreturn/infoSaleReturn'
import SaleReturnDetails from '@/models/salesreturn/SaleReturnDetails'
import OptionSaleDetProducts from '@/models/salesreturn/OptionSaleDetProducts'
import UpdSaleReturn from '@/models/salesreturn/UpdSaleReturn';
import OptionSelectClient from '@/models/sales/clientsSale';
import OptionSelectSale from '@/models/salesreturn/OptionSale';
import FilterOptionSale from '@/models/salesreturn/filters/FilterOptionSale'

@Module({dynamic: true, store, namespaced: true, name: Modules.NewSaleReturnModule})
export default class NewSaleReturnModule extends VuexModule {
    idSaleSelected = '';
    idProductSelected = '';
    pdf = '';
    idClientSelected = '';

    initialValues = formDefaultValues;
    initialReturnSaleDetail = detailDefaultValue;
    lstReturnSaleDetail = defaultSaleReturnDetail;
    lstSaleDetails = defaultSaleReturnDetail;
    loading = false;
    loadingClients = false;

    selects = {
          products: [] as OptionSaleDetProducts[]
        , clients: [] as OptionSelectClient[]
        , Sale: [] as OptionSelectSale[]
    }

    get getSaleReturnDetail(): SaleReturnDetails
    {
        return this.initialReturnSaleDetail;
    }

    get getValuesForm(): InfoSaleReturn {
        return this.initialValues;
    }

    get getDetailsProducts(): OptionSaleDetProducts[]{        
        if(this.initialValues){
            if(this.initialValues.saleDetails){
                this.initialValues.saleDetails.forEach(item => { 
                    this.selects.products.push({id:item.productId, name:item.productName, quantity: item.quantity});    
                });
            }else{
                this.initialValues.saleReturnDetails.forEach(item => { 
                    this.selects.products.push({id:item.productId, name:item.productName, quantity: item.quantity});    
                });
                
            }
            const filteredArr= this.selects.products.filter((currentUser, index) => {
                return (
                    this.selects.products.findIndex((user) => user.id === currentUser.id) === index
                );
            });
            this.selects.products = filteredArr;
        }           
        return this.selects.products;
    }

    get getSelectedSaleDetail():SaleReturnDetails{
        return this.initialValues.saleDetails.find(x => x.productId === this.idProductSelected) as SaleReturnDetails;
    }

    get getSaleDetailsAll(): SaleReturnDetails []{
        return this.initialValues.saleDetails;
    }

    get getValuesDetailsForm(): SaleReturnDetails {
        return this.initialReturnSaleDetail;
    }

    get getImportSale() : number {
        return this.initialReturnSaleDetail == undefined ? 
                    0 : 
                    this.initialReturnSaleDetail.subTotal;
    }

    get getDiscontSale() : number {
        return this.initialValues == undefined ? 
                    0 : 
                    this.initialValues.discount;
    }

    get getTaxSale() : number {
        return this.initialValues == undefined ? 
                    0 : 
                    this.initialValues.tax;
    }

    get getPercentReturn() : number {
        return this.initialValues == undefined ? 
                    0 : 
                    this.initialValues.percentReturn; 
    }

    get getTotalSale() : number {
        let total = 0; 

        if(this.lstReturnSaleDetail != undefined && this.lstReturnSaleDetail.length > 0) {
            
            total = this.lstReturnSaleDetail?.filter(x => x.status == true).reduce((sum, current) => sum + current.total, 0);
            
            if(total > 0){
                total = total - (this.initialValues.penaltyReturn != null && 
                    this.initialValues.penaltyReturn > 0 ? this.initialValues.penaltyReturn : 0)
            }
            
        }else{
           return 0;
        }   

        return total
    }

    get getSubtotalSale() : number {
        let subtotal = 0;
        if(this.initialValues != undefined ){
            subtotal = this.initialValues == undefined ? 
                                0 : 
            this.initialValues.saleDetails.reduce((sum, current) => sum + current.subTotal, 0);
        }    
        return subtotal;
    }

    get getPercentageReturn() : number {
        return this.initialValues == undefined ? 
                    0 : 
                    this.initialValues.percentReturn == null ? 100 : (this.initialValues.percentReturn == 0 ? 
                                                                                100 : this.initialValues.percentReturn);
    }

    get getReturnTotal() : number {
        return this.initialValues == undefined ? 
                    0 : 
                    this.initialValues.returnTotal == null ? 0 : this.initialValues.returnTotal;
    }

    get getPenalization() : number {
        return this.initialValues == undefined ? 
                    0 : 
                    this.initialValues.penaltyReturn == null ? 0 : this.initialValues.penaltyReturn;
    }

    get getTotalSaleDetail() : number{
        
        return this.initialReturnSaleDetail == undefined ? 
                    0 : 
                    (this.initialReturnSaleDetail.total_Ori != null && 
                     this.initialReturnSaleDetail.total_Ori > 0 ? this.initialReturnSaleDetail.total_Ori 
                                                              : this.initialReturnSaleDetail.total) + this.initialReturnSaleDetail.tax;
    }

    get getSaleReturnDetailsAdd(): SaleReturnDetails []{
        return this.lstReturnSaleDetail;
    }

    get getTableSaleReturnDetailsAdd(): SaleReturnDetails []{
        return this.lstReturnSaleDetail?.filter(x => x.status == true);
    }

    get getSelectedSaleDetailAdd():SaleReturnDetails{
        if(this.lstReturnSaleDetail != undefined && this.lstReturnSaleDetail.length > 0){
            if(this.lstReturnSaleDetail.some(item => item.productId == this.idProductSelected)){
                return this.lstReturnSaleDetail.find(x => x.productId === this.idProductSelected) as SaleReturnDetails;
            }else{
                return this.initialValues.saleDetails.find(x => x.productId === this.idProductSelected) as SaleReturnDetails;
            }
            
        }else{
            return this.initialValues.saleDetails.find(x => x.productId === this.idProductSelected) as SaleReturnDetails;
        }        
    }

    get getImportSubtotalDetails() : number {
        if(this.lstReturnSaleDetail != undefined && this.lstReturnSaleDetail.length > 0) {
            return (this.lstReturnSaleDetail?.filter(x => x.status == true)
                        .reduce((sum, current) => sum + current.total, 0))
        }else{
           return 0;
        }                    
    }

    get getImportTotalDetails() : number {
        if(this.lstReturnSaleDetail != undefined && this.lstReturnSaleDetail.length > 0) 
        {
            return (this.lstReturnSaleDetail?.filter(x => x.status == true).reduce((sum, current) => sum + current.subTotal, 0))
        }else{
           return 0;
        }
    }

    get getImportTotalTaxDetails() : number {
        if(this.lstReturnSaleDetail != undefined && this.lstReturnSaleDetail.length > 0) 
        {
            return (this.lstReturnSaleDetail?.filter(x => x.status == true).reduce((sum, current) => sum + current.tax, 0))
        }else{
           return 0;
        }
    }

    get getExistSaleDetailAdd(): boolean{        
        if(this.lstReturnSaleDetail != undefined && this.lstReturnSaleDetail.length > 0){
            return this.lstReturnSaleDetail.some(item => item.productId == this.idProductSelected  && item.status) ? true : false;
        }else{
            return false;
        }
    }

    get getSaleClientName() : string {
        return this.initialValues == undefined ? 
                    "" : 
                    this.initialValues.customer;
    }

    get getSaleRFC() : string {
        return this.initialValues == undefined ? 
                    "" : 
                    this.initialValues.rfc;
    }

    get getbase64PDF(){
        return this.pdf;
    }

    get getClientOptions(): OptionSelectClient[] {
        return this.selects.clients;
    }

    get getSaleOptions(): OptionSelectSale[] {
        return this.selects.Sale;
    }

    get getClientSelected(): string {
        return this.idClientSelected;
    }

    @Mutation
    [Mutations.SAVE_SALE_SELECTED](id: string){
        this.idSaleSelected = id;
    }

    @Mutation
    [Mutations.SAVE_PRODUCT_SELECTED](id: string){
        this.idProductSelected = id;
    }

    @Mutation
    [Mutations.RESET_SALERETURN](){
        
        this.initialValues = {
            id:"",
            saleId: "",
            retrurnStatus: 0,
            customerId: "",
            customer: "",
            rfc: "",
            addressId: "",
            total: 0,
            subTotal: 0,
            discount: 0,
            tax: 0,
            percentReturn: 0,
            returnTotal: 0,
            penaltyReturn: 0,
            deliveryStatus: 0,
            observation: "",
            saleReturnAt: "",
            saleReturnDetails: [],
            saleDetails: []
        } as InfoSaleReturn;
         
        this.initialReturnSaleDetail = detailDefaultValue;
        this.lstReturnSaleDetail = [] as SaleReturnDetails[]; 
        this.selects.products = [] as OptionSaleDetProducts[];
        this.lstSaleDetails = [] as SaleReturnDetails[]; 
    }

    @Mutation
    [Mutations.SAVE_EDIT_PRODUCT](value: SaleReturnDetails){
        this.initialReturnSaleDetail = value;
        //Se actualiza el valor del total y el amount
        this.initialValues.saleDetails.forEach(p => {
            if(p.productId === value.productId){
                p.subTotal = value.subTotal;
                p.total = value.total;
                p.amount = value.amount;
            }
        })
    }
    @Mutation
    [Mutations.UPD_SALERETURN_PRODUCT](value: SaleReturnDetails){
        //Se actualiza con el id del salereturnid generado
        this.initialValues.saleDetails.forEach(p => {
            if(p.productId === value.productId){
                p.subTotal = value.subTotal;
                p.total = value.total;
                p.amount = value.amount;
                p.saleReturnId = value.saleReturnId;
            }
        })
    }

    @Mutation
    [Mutations.SET_INITIAL_DATA_SALE](values: InfoSaleReturn){
           
        const data = values as InfoSaleReturn;
            this.initialValues = data;
    }

    @Mutation
    [Mutations.SAVE_SALERETURN_SELECTED](value: SaleReturnDetails){
        
        if(this.lstReturnSaleDetail == undefined){
            this.lstReturnSaleDetail = [value];
        }else{
            this.lstReturnSaleDetail.push(value);
        }
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }

    @Mutation
    [Mutations.SALERETURN_SELECTEDALL](values: InfoSaleReturn){
        if(this.initialValues != undefined){
            this.initialValues.saleDetails.forEach(item =>{
                if(this.lstReturnSaleDetail == undefined){
                    this.lstReturnSaleDetail = [item];
                }else{
                    this.lstReturnSaleDetail.push(item);
                }
            });

            this.lstReturnSaleDetail.forEach(item => {
                item.total_Ori = item.total;
                item.quantity_Ori = item.amount;
            })
        }
    }

    @Mutation
    [Mutations.UPD_SALERETURN_PRODUCTGRID](value: SaleReturnDetails){
        //Se actualiza con el id del salereturnid generado
        this.lstReturnSaleDetail.forEach(p => {
            if(p.productId === value.productId){
                p.subTotal = value.subTotal;
                p.total = value.total;
                p.amount = value.amount;
                p.saleReturnId = value.saleReturnId;
            }
        })
    }

    @Mutation
    [Mutations.DEL_SALERETURN_PRODUCTGRID](id: string){
        //Se elimina el producto del grid
        const objItems = this.lstReturnSaleDetail.filter((item) => item.productId !== id);
        if(objItems && objItems.length > 0)
        {
            this.lstReturnSaleDetail = [] as SaleReturnDetails[]; 
            objItems.forEach(item => {
                this.lstReturnSaleDetail.push(item);
            });
        }else{
            this.lstReturnSaleDetail = [] as SaleReturnDetails[]; 
        } 
        
        this.initialValues.saleDetails.forEach(item =>{
            if(item.productId === id){
                item.total = item.price * item.quantity_Ori;
                item.amount = item.quantity_Ori;
                item.subTotal = item.price * item.quantity_Ori;
            }
        })
    }

    @Mutation
    [Mutations.SET_INITIAL_DATA_SALERETURN](values: InfoSaleReturn){
        const data = values as InfoSaleReturn;
        this.initialValues = data;
        this.initialValues.saleReturnDetails.forEach(item => {
            if(this.lstReturnSaleDetail == undefined){
                this.lstReturnSaleDetail = [item];
            }else{
                this.lstReturnSaleDetail.push(item);
            }
            item.quantity = item.amount;
            item.quantity_Ori = item.amount;
            if(this.initialValues.saleDetails == undefined){
                this.initialValues.saleDetails = [item];
            }else{
                this.initialValues.saleDetails.push(item);
            }
        });

        this.lstSaleDetails.forEach(item => {
            const index = this.initialValues.saleDetails.findIndex(d => d.productId == item.productId);        
            if (index == -1) {
                item.quantity = item.amount;
                item.quantity_Ori = item.amount;
                this.initialValues.saleDetails.push(item);
            }
        })
    }

    @Mutation
    [Mutations.SET_INITIAL_DATA_SALEDETAILS](values: SaleReturnDetails []){
        values.forEach(item => {
            item.quantity = item.amount;
            item.quantity_Ori = item.amount;
            if(this.lstSaleDetails == undefined){
                this.lstSaleDetails = [item];                
            }else{
                this.lstSaleDetails.push(item);
            }            
        });
    }

    @Mutation
    [Mutations.UPD_SALE_RETURNTOTAL](values: number){
        this.initialValues.returnTotal = values;
    }

    @Mutation
    [Mutations.UPD_SALE_PENALIZATION](values: number){
        this.initialValues.penaltyReturn = values;
    }
    
    @Action
    async [Actions.SAVE_NEW_SALERETURN](form: InfoSaleReturn){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.saveSaleReturn(form)
            .then((resp) => {
                /*if(resp.data){
                    realoadTable(this.context, Modules.ClientTableModule)
                }*/ 
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
                //this.context.commit(Mutations.RESET_VALUES_FORM)
            })
    }

    @Action
    async [Actions.UPD_SALERETURN](form: UpdSaleReturn){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.updSaleReturn(form)
            .then((resp) => {
                /*if(resp.data){
                    realoadTable(this.context, Modules.ClientTableModule)
                }*/ 
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
                //this.context.commit(Mutations.RESET_VALUES_FORM)
            })
    }

    @Mutation
    [Mutations.SET_PDF](base64: string){
        this.pdf = "data:application/pdf;base64," + base64 
    }

    @Mutation
    [Mutations.SET_CLIENTS](value: OptionSelectClient[]){
        this.selects.clients = value;
    }

    @Mutation
    [Mutations.SET_LOADING_CLIENTS](value: boolean){
        this.loadingClients = value;
    }

    @Mutation
    [Mutations.SAVE_CLIENT_SELECTED](id: string){
        this.idClientSelected = id;
    }

    @Mutation
    [Mutations.SET_OPTIONSALE](value: OptionSelectSale[]){
        this.selects.Sale = []
         value.forEach(item => {
            this.selects.Sale.push({id: item.id, 
                                    name: item.invoince, 
                                    invoince: item.invoince, 
                                    salesStatus: item.salesStatus});           
        });        
    }
    

    @Action({ commit: Mutations.SET_INITIAL_DATA_SALE })
    async [Actions.SEARCH_SALEDETAILS](filterName: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getSaleDetails(filterName)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_INITIAL_DATA_SALERETURN })
    async [Actions.SEARCH_INFO_SALERETURN](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.searchSaleReturnInfo(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? formDefaultValues
    }

    @Action({ commit: Mutations.SET_INITIAL_DATA_SALEDETAILS })
    async [Actions.SEARCH_INFO_SALEDETAILS](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.searchSaleDetails(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_PDF })
     async [Actions.GET_AUTHORIZEDPDF](id: string){
        return (await service.getFilePDF(id)
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? "";
    }

    @Action({ commit: Mutations.SET_CLIENTS })
    async [Actions.SEARCH_CLIENTS](filterName: string){
        this.context.commit(Mutations.SET_LOADING_CLIENTS, true);
        return (await service.searchClientOptions(filterName)
            .finally( () => this.context.commit(Mutations.SET_LOADING_CLIENTS, false))).data ?? []
    }

    @Action ({ commit: Mutations.SET_OPTIONSALE })
    async [Actions.SEARCH_OPTIONSALE] (filterOption: FilterOptionSale ){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.searchSaleOptions(filterOption)
                .finally(()=> this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }
}
const service = new SaleReturnService();

const formDefaultValues = {
    id:"",
    saleId: "",
    retrurnStatus: 0,
    customerId: "",
    customer: "",
    rfc: "",
    addressId: "",
    total: 0,
    subTotal: 0,
    discount: 0,
    tax: 0,

    percentReturn: 0,
    returnTotal: 0,
    penaltyReturn: 0,
    deliveryStatus: 0,

    observation: "",
    saleReturnAt: "",
    saleReturnDetails: [],
    saleDetails: []
} as InfoSaleReturn;

const detailDefaultValue = {
    id: "",
    saleReturnId: "",
    saleId: "",
    saleDetailId: "",
    productId: "",
    productName: "",
    quantity: 0,
    unitOfMeasurementId: "",
    price: 0,
    tax: 0,
    amount: 0,
    subTotal: 0,
    total: 0,
    taxPersent: 0,
    discount: 0,
} as SaleReturnDetails;

const defaultSaleReturnDetail = [] as SaleReturnDetails[];