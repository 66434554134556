export enum Mutations {
      SET_VALUE_LOADING = "SET_VALUE_LOADING"
    , SET_TABLE_DATA = "SET_TABLE_DATA"
    , SET_PAGINATION = "SET_PAGINATION"
    , SET_FILTERS = "SET_FILTERS"
    , SET_TOTAL_RECORDS = "SET_TOTAL_RECORDS"
    , SET_OPTIONSALE = "SET_OPTIONSALE"
    , SET_LOADING_OPTIONSALE = "SET_LOADING_OPTIONSALE"
    , SAVE_SALE_SELECTED = "SAVE_SALE_SELECTED"
    , SAVE_PRODUCT_SELECTED = "SAVE_PRODUCT_SELECTED"
    , SAVE_EDIT_PRODUCT = "SAVE_EDIT_PRODUCT"
    , SET_INITIAL_DATA_SALE = "SET_INITIAL_DATA_SALE"
    , SAVE_SALERETURN_SELECTED = "SAVE_SALERETURN_SELECTED"
    , UPD_SALERETURN_PRODUCT = "UPD_SALERETURN_PRODUCT"
    , SALERETURN_SELECTEDALL = "SALERETURN_SELECTEDALL"
    , UPD_SALERETURN_PRODUCTGRID = "UPD_SALERETURN_PRODUCTGRID"
    , DEL_SALERETURN_PRODUCTGRID = "DEL_SALERETURN_PRODUCTGRID"
    , RESET_SALERETURN = "RESET_SALERETURN"
    , SET_INITIAL_DATA_SALERETURN = "SET_INITIAL_DATA_SALERETURN"
    , SET_PDF = "SET_PDF"
    , SET_INITIAL_DATA_SALEDETAILS = "SET_INITIAL_DATA_SALEDETAILS"
    , UPD_SALE_RETURNTOTAL = "UPD_SALE_RETURNTOTAL"
    , SET_CLIENTS = "SET_CLIENTS"
    , SET_LOADING_CLIENTS = "SET_LOADING_CLIENTS"
    , SAVE_CLIENT_SELECTED = "SAVE_CLIENT_SELECTED"
    , UPD_SALE_PENALIZATION = "UPD_SALE_PENALIZATION"
}

export default Mutations;