import { getMessageError, getMessageHeader } from '@/core/helpers/messageFromPulmeros';
import { translate } from '@/core/plugins/i18n';
import PaginationRequest from '@/models/general/PaginationRequest';
import PaginationResponse from '@/models/general/PaginationResponse';
import OptionSelectSale from '@/models/salesreturn/OptionSale';
import FilterReturnSale from '@/models/salesreturn/FilterReturnSale';
import NewSale from '@/models/sales/NewSale';
import NewSaleDetail from '@/models/sales/NewSaleDetail';
import NewSaleReturnResponse from '@/models/salesreturn/NewSaleReturnResponse';
import ReturnSaleRow from '@/models/salesreturn/ReturnSaleRows';
import { AxiosResponse } from 'axios';
import ApiService from '../ApiService';
import GenericCrudService from "../general/GenericCrudService";
import SwalMessageService from '../SwalMessageService';
import InfoSaleReturn from "@/models/salesreturn/infoSaleReturn"
import SaleReturnDetails from '@/models/salesreturn/SaleReturnDetails'
import UpdSaleReturn from '@/models/salesreturn/UpdSaleReturn';
import OptionSelectClient from '@/models/sales/clientsSale';
import FilterOptionSale from '@/models/salesreturn/filters/FilterOptionSale'


export default class ReturnSaleService 
    extends GenericCrudService<NewSale<NewSaleDetail>, ReturnSaleRow> {

    constructor(){
        super("/SaleRerturn", new SwalMessageService() );
    }
   
    /**
     * Recupera información de la venta para el grid
     * @param request filtros para recuperar ventas
     * @returns listado de ventas
     */
    async getTableInfo(paginator: PaginationRequest, filter: FilterReturnSale): Promise<AxiosResponse<PaginationResponse<ReturnSaleRow>>> {
        return  ApiService.get<PaginationResponse<ReturnSaleRow>>(this.apiController, 
            `GetPagedList?`
            + `CurrentPage=${paginator.currentPage}`
            + `&PageSize=${paginator.pageSize}`
            + (filter.rfc != undefined && filter.rfc != 'undefined' ? `&Criteria.RFC=${filter.rfc}` : '')
            + (filter.invoice != undefined && filter.invoice != 'undefined' ? `&Criteria.SaleReturnFolio=${filter.invoice}` : '')
            + (filter.Name != undefined && filter.Name != 'undefined' ? `&Criteria.Name=${filter.Name}` : '')
            + (filter.saleReturnStatus != undefined && filter.saleReturnStatus != 'undefined' 
                                                    && filter.saleReturnStatus != '' ? filter.saleReturnStatus : '')           
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchSaleOptions(filterSale: FilterOptionSale): Promise<AxiosResponse<OptionSelectSale[]>> {
        return  ApiService.get<OptionSelectSale[]>("Sales", 
              `combo?`
            + `IsPayed=true`
            + `&Active=true`
            + (filterSale.id != undefined && filterSale.id != 'undefined' ? `&Invoice=${filterSale.id}` : '')
            + (filterSale.idClientSelected != undefined && 
                               filterSale.idClientSelected != 'undefined' ? `&CustomerId=${filterSale.idClientSelected}` : '')

        ).then( resp => {            
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async getSaleDetails(id: string): Promise<AxiosResponse<InfoSaleReturn>>{
        return  ApiService.get("Sales/" 
            , id
        ).then( resp => {
            return resp;
        }).catch(error => {
            console.log(error);
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async saveSaleReturn(form: InfoSaleReturn): Promise<AxiosResponse<NewSaleReturnResponse>> {
        return ApiService.post<NewSaleReturnResponse>(this.apiController,
            form
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return resp;
        }).catch((error) => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }

    async approveSaleReturn(id: string): Promise<AxiosResponse<boolean>> {
        return ApiService.put(this.apiController + `/${id}/ApproveSaleReturn`
        , id
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, "No se realizo correctamente la aprobación", this.messageService);
            }
            return resp;
        }).catch((error) => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchSaleReturnInfo(id: string): Promise<AxiosResponse<InfoSaleReturn>>{
        return  ApiService.get(this.apiController
            , id
        ).then( resp => {
            return resp;
        }).catch(error => {
            console.log(error);
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async updSaleReturn(form: UpdSaleReturn): Promise<AxiosResponse<NewSaleReturnResponse>> {
        return ApiService.put(this.apiController + `/${form.id}`,
            form
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return resp;
        }).catch((error) => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }

    async getFilePDF(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.get<boolean>(this.apiController, 
        `GetReceiptView/${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchSaleDetails(id: string):  Promise<AxiosResponse<SaleReturnDetails>>{
        return  ApiService.get<SaleReturnDetails>("SalesDetail", `?SaleId=${id}`  
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchClientOptions(filterName: string): Promise<AxiosResponse<OptionSelectClient[]>> {
        return  ApiService.get<OptionSelectClient[]>("Customer", 
            `combo?`
            + `criteria=${filterName}`     
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}