import PaginationRequest from "@/models/general/PaginationRequest";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Mutations from '../new-salereturn/Mutations';
import Actions from '../new-salereturn/Actions';
import store from '@/store';
import Modules from "@/store/enums/Modules";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import { realoadTable } from '@/store/functions/GenericTable';
import TableReturnSaleState from "../../models/TableReturnSaleState";
import ReturnSaleRow from "@/models/salesreturn/ReturnSaleRows";
import ReturnSaleService from "@/core/services/salesreturn/ReturnSaleService";
import FilterReturnSale from "@/models/salesreturn/FilterReturnSale";

@Module({dynamic: true, store, namespaced: true, name: Modules.TableSaleReturnModule})
export default class TableReturnSaleModule extends VuexModule implements TableReturnSaleState {

    records = [] as ReturnSaleRow[];
    totalRecords = 0;
    filters = {
        invoice: ''
        , rfc: ''
        , Name: ''
        , LastName: ''
        , saleReturnStatus: '0'
        , lstSaleReturnStatus: []
    } as FilterReturnSale;
    pagination = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    loading = false;

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: ReturnSaleRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

    /**
     * Se obtienen los datos de la tabla de clientes mediante la paginación
     * @param paginator paginador de las notas
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        
        this.filters.saleReturnStatus = ''
        if(this.filters.lstSaleReturnStatus)
        {
            if(this.filters.lstSaleReturnStatus.length > 0)
            {
                this.filters.lstSaleReturnStatus.forEach(item => {
                    this.filters.saleReturnStatus = this.filters.saleReturnStatus + `&Criteria.SaleReturnStatus=${item}`
                });
            }else{
                this.filters.saleReturnStatus = `&Criteria.SaleReturnStatus=0&Criteria.SaleReturnStatus=2`
            }                
        }
            
        const resp = (await service.getTableInfo(this.pagination, this.filters)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ??
                {
                    data: [] as ReturnSaleRow[]
                    , totalPages: 0
                    , currentPage: this.pagination.currentPage
                }
 
        this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
        this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
        this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
    }

    /**
       * Realiza un busqueda con la nueva paginación
       * @param pagination paginación de la tabla
       */
     @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Filtros que se aplican y despues se hace la busqueda de los datos
     * @param filters filtros nuevos
     */
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
        this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }

    @Action
    async [Actions.APPROVE_SALERETURN](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.approveSaleReturn(id)
            .then((resp) => {                
                //if(resp.data){
                    realoadTable(this.context, Modules.TableSaleReturnModule)
                    //this.context.dispatch(Actions.SEARCH_TABLE_DATA)
                //} 
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            });
    }
}

/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
 const service =  new ReturnSaleService()
