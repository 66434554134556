export enum Actions {
    // action types
      SEARCH_TABLE_DATA = "SEARCH_TABLE_DATA"
    , CHANGE_PAGE = "CHANGE_PAGE"
    , UPDATE_FILTERS = "UPDATE_FILTERS"
    , SEARCH_OPTIONSALE = "SEARCH_OPTIONSALE"
    , SEARCH_SALEDETAILS = "SEARCH_SALEDETAILS"
    , SAVE_NEW_SALERETURN = "SAVE_NEW_SALERETURN"
    , APPROVE_SALERETURN = "APPROVE_SALERETURN"
    , SEARCH_INFO_SALERETURN = "SEARCH_INFO_SALERETURN"
    , GET_AUTHORIZEDPDF = "GET_AUTHORIZEDPDF"
    , SEARCH_INFO_SALEDETAILS = "SEARCH_INFO_SALEDETAILS"
    , UPD_SALERETURN = "UPD_SALERETURN"
    , SEARCH_CLIENTS = "SEARCH_CLIENTS"
}

export default Actions;